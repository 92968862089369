import React, { CSSProperties, useEffect, useState } from "react";

import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  SelectProps,
  Modal,
  Radio,
  Transition,
  InputProps,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";


// UI
import MobBottomBar from "./MobBottomBar";

//type
import { profileInfo } from "./TypeModal";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import createStyles from "@mui/material/styles/createStyles";
import { ChatList } from "../Chat";
import Cookies from "js-cookie";
import { useIntl } from "react-intl";

type MobMainIshealthTypes = {
  noApiToken: Function;
  onEvent?: any;
  history: any;
  apiToken: any;
  profileInfo?: profileInfo;
  patientData: any;
  // config
  mainWebSide?: string;
};

const images = {
  titleImage: "/images/ishealth/title_image.png",
  doctorImage: "/images/ishealth/doctor_image.png",
  detailImage: "/images/ishealth/detail_image.png",
  artImage: "/images/ishealth/art_bottom_image.png",
};

const styles = {
  mainScreen: {
    padding: "20px",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  sloganTitle: {
    fontWeight: "bold",
    fontSize: "15px",
    color: "#005FB9",
  } as CSSProperties,
  sloganDetail: {
    fontSize: "15px",
    color: "#66767F",
  } as CSSProperties,
  DetailText: {
    fontSize: "13px",
    color: "#334855",
    paddingBottom: "5px",
  } as CSSProperties,
};

const sloganList = [
  {
    title: "Superior",
    detail: "การรักษาและบริการที่เหนือกว่า",
  },
  {
    title: "Achieve",
    detail: "ทำได้สำเร็จตามเป้าหมาย",
  },
  {
    title: "Painless",
    detail: "ไร้ความเจ็บปวด",
  },
  {
    title: "Intelligent",
    detail: "เป็นโรงพยาบาลที่ทรงภูมิปัญญา",
  },
  {
    title: "Expert",
    detail: "แพทย์ผู้เชี่ยวชาญ ชั้นนำระดับประเทศ",
  },
  {
    title: "Neo",
    detail: "อุปกรณ์ทางการแพทย์และนวัตกรรมที่ทันสมัย",
  },
  {
    title: "Service",
    detail: "การบริการเป็นเลิศ",
  },
];

const MobMainIshealth = (props: MobMainIshealthTypes) => {
  const intl = useIntl();
  const [screenLandscape, setScreenLandscape] = useState<boolean>(true);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      console.log("saika ~MobMainIshealth noApiToken:");
      props.noApiToken();
    }
    props.onEvent({
      message: "HandleCheckConsent",
      params: { history: props.history, readOnly: true },
    });
  }, []);

  useEffect(() => {
    if (props.patientData?.patient) {
      if (globalThis.iosNative?.savePatient) {
        globalThis.iosNative.savePatient(`${props.patientData?.patient}`);
      } else if (globalThis.MobNative?.savePatient) {
        globalThis.MobNative.savePatient(`${props.patientData?.patient}`);
      }
    }
  }, [props.patientData]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      checkScreenPosition();
    });
    checkScreenPosition();
  }, []);

  const checkScreenPosition = () => {
    if (window.innerWidth < window.innerHeight) {
      setScreenLandscape(false);
    } else {
      setScreenLandscape(true);
    }
  };
  const handleUpdateProfile = () => {
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: { history: props.history },
    });
  };
  return (
    <>
      {props.mainWebSide ? (
        <iframe src={props.mainWebSide} width={"100%"} height={"100%"} style={{border: "0" , marginBottom: "63px"}}></iframe>
      ) : (
        <div className="content-detail">
          <div style={{ position: "relative" }}>
            <div style={styles.mainScreen}>
              <Image src={images.titleImage} />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bolder",
                  color: "#005FB9",
                  paddingBottom: "5px",
                }}
              >{intl.formatMessage({ id: "โรงพยาบาลเฉพาะทางรักษาความเจ็บปวด" })}</div>
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  color: "#669FD5",
                }}
              >{intl.formatMessage({ id: "โดยแพทย์ผู้เชี่ยวชาญ แห่งแรกและแห่งเดียวในประเทศไทย" })}</div>
              <Image src={images.doctorImage} style={{ margin: "5px 0 0 0" }} />
              <Image src={images.detailImage} style={{ margin: "0 0 5px 0" }} />
              <div style={styles.DetailText}>{intl.formatMessage({ id: "ศูนย์ระงับความเจ็บปวดที่ได้ผลรวดเร็ว" })}</div>
              <div style={styles.DetailText}>{intl.formatMessage({ id: "ด้วยวิธีทันสมัยไร้แผลผ่าตัด มีคุณภาพเป็นเลิศ อันดับหนึ่งในประเทศ" })}</div>
            </div>
            <div style={{ zIndex: 2, paddingTop: "10px", flex: 1 }}>
              {sloganList.map((item) => (
                <div style={{ display: "flex", padding: "5px 20px" }}>
                  <div style={{ width: "20%", marginRight: "10px" }}>
                    <label style={styles.sloganTitle}>
                      {item.title.charAt(0)}
                    </label>
                    <label style={styles.sloganDetail}>
                      {item.title.substring(1, item.title.length)}
                    </label>
                  </div>
                  <div>
                    <label style={styles.sloganDetail}>{item.detail}</label>
                  </div>
                </div>
              ))}
            </div>
            <div
              style={{
                bottom: "0px",
                width: "100%",
                zIndex: 1,
                maxHeight: "168px",
              }}
            >
              <Image
                src={images.artImage}
                style={{
                  left: "50%",
                  transform: "translate(-50%, 0px)",
                  width: "100%",
                  bottom: "50px",
                  maxHeight: "168px",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

MobMainIshealth.displayname = "MobMainIshealth";
export default MobMainIshealth;
