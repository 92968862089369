import React, { MutableRefObject, useRef, useEffect } from "react";

import { useIntl } from "react-intl";

// Types
type MessageContentHTMLProps = {
  content: string;
};

const MessageContentHTML = (props: MessageContentHTMLProps) => {
  const intl = useIntl();

  const contentRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (contentRef.current) {
      const datai18n = document.querySelectorAll("[data-i18n-en]");
      let lang = intl.locale as "th" | "en";

      if (lang.includes("-")) {
        lang = intl.locale.split("-")[0] === "th" ? "th" : "en"
      }

      for (const elm of Array.from(datai18n)) {
        const messages = {
          th: elm.getAttribute("data-i18n-th"),
          en: elm.getAttribute("data-i18n-en"),
        };

        elm.innerHTML = messages[lang] || "";
      }

      contentRef.current.style.opacity = "1";
    }
  }, [contentRef.current, props.content]);

  return (
    <div
      ref={contentRef}
      style={{ opacity: 0 }}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};

export default React.memo(MessageContentHTML);
