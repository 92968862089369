/*eslint indent: 0, quotes: 0, semi: 0, no-unused-vars: 0, no-var: 0*/
import config from "config/config";
import BaseService from "react-lib/apis/services/BaseService";
import { to } from 'react-lib/utils'


export default (function () {
  /**
   * XmlHttpRequest's getAllResponseHeaders() method returns a string of response
   * headers according to the format described here:
   * http://www.w3.org/TR/XMLHttpRequest/#the-getallresponseheaders-method
   * This method parses that string into a user-friendly key/value pair object.
   */
  var parseResponseHeaders = function (headerStr: string) {
    var headers: any = {};
    if (!headerStr) {
      return headers;
    }
    var headerPairs: any = headerStr.split("\u000d\u000a");
    for (var i = 0, len = headerPairs.length; i < len; i++) {
      var headerPair = headerPairs[i];
      var index = headerPair.indexOf("\u003a\u0020");
      if (index > 0) {
        var key = headerPair.substring(0, index);
        var val = headerPair.substring(index + 2);
        headers[key] = val;
      }
    }
    return headers;
  };
  var printURL = function (pdf_url: any) {
    console.log("common Local printing..." + pdf_url);
    var iframe: any = document.getElementById("vc_local_print");
    if (iframe !== null) {
      document.body.removeChild(iframe);
    }

    // recreate iframe everytime to reliably display print dialog
    iframe = document.createElement("iframe");
    iframe.id = "vc_local_print";
    iframe.style.display = "none";
    iframe.addEventListener("load", function () {
      if (iframe.src === "" || iframe.src === "about:blank") {
        return;
      }
      iframe.contentWindow.print();
    });
    document.body.appendChild(iframe);
    if (config.API_HOST) {
      iframe.src = `${config.API_HOST}${pdf_url}`;
    } else {
      iframe.src = pdf_url;
    }
  };
  // var localPrint = function (xhr: any) {
  //   //   var headerText = xhr.getAllResponseHeaders();
  //   var headerText = xhr.headers;
  //   //   if (headerText.indexOf('x-local-print') > -1) {
  //   if (headerText["x-local-print"]) {
  //     //   var headers = parseResponseHeaders(headerText);
  //     var print_id = headerText["x-local-print"];
  //     printURL("/users/apis/local-print/" + print_id);
  //   }
  // };

  var localPrint = async function (xhr: any, apiToken: any = null) {
    // var headerText = xhr.getAllResponseHeaders();
    var headerText = xhr.headers;

    // print เมื่อ มี x-local-print ใน header และ ไม่ส่ง pdf: false มาใน config
    if (headerText["x-local-print"] && !("pdf" in xhr.config && !xhr.config.pdf)) {
      console.log("localPrint print เมื่อ มี x-local-print ใน header และ ไม่ส่ง pdf: false มาใน config")
      console.log('localPrint xhr.config: ', xhr.config);
      console.log('localPrint xhr.config.pdf: ', xhr.config.pdf);
      //   var headers = parseResponseHeaders(headerText);
      var print_id = headerText["x-local-print"];
      console.log('localPrint apiToken: ', apiToken);
      if (apiToken === null ) {
        console.log("localPrint /users/apis/local-print/", print_id )
      } else { 

        console.log("localPrint config.API_HOST: ", config.API_HOST);
        const base = new BaseService()
        if (apiToken) {
          base.client.defaults.headers["Authorization"] = "Token " + apiToken;
        }

        console.log("localPrint $config.API_HOST/users/apis/local-print-base64/$print_id", `${config.API_HOST}/users/apis/local-print-base64/${print_id}`)
        const baseResult  = await to(base.client.get(`${config.API_HOST}/users/apis/local-print-base64/${print_id}`, { params: {} })
          .then(base.handleResponse)
          .catch(base.throwErrorMessage))
        const result = [baseResult[1], baseResult[0], baseResult[2]]
        
        console.log("localPrint result", result)
        if (result[0]) {
          // displayPDF(result[0].pdf_b64data);
          var iframe: any = document.getElementById("vc_local_print");
          if (iframe !== null) {
            document.body.removeChild(iframe);
          }
  
          // recreate iframe everytime to reliably display print dialog
          iframe = document.createElement("iframe");
          iframe.id = "vc_local_print";
          iframe.style.display = "none";
          iframe.addEventListener("load", function () {
            if (iframe.src === "" || iframe.src === "about:blank") {
              return;
            }
            iframe.contentWindow.print();
          });
          document.body.appendChild(iframe);
  
          // ทำ blob เข้า url TODO: เลิกใช้ atob
          const contentType = "application/pdf";
          const byteCharacters = atob(result[0].pdf_b64data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: contentType,
          });
          const blobUrl = URL.createObjectURL(blob);
          console.log('localPrint blobUrl: ', blobUrl);
  
          iframe.src = blobUrl;
        }
      }

      
    }
  };

  return localPrint;
})();
