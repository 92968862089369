import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Icon,
  Divider,
  Button,
  Modal,
  Segment,
  Image,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import { useIntl } from "react-intl";

import {
  Organizations
} from "react-lib/apps/IsHealth/IHMobile/TypeModal";

interface CardManageAppointmentsProps {
  contentPayload?: any;
  setProps?: any;
  history?: any;
  onEvent?: any;
  apiToken?: any;
  patientData?: any;
  remindInComing?: boolean;
  loadingStatus?: any;
  patientAppointmentList?: any;
  DISABLE_CREATE_APPOINTMENT?: any;
  organizationList?: Organizations;
}

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    color: "#1AB3CB",
    fontSize: "18",
    fontWeight: "bold",
  },
}));

const CardManageAppointments = (props: CardManageAppointmentsProps) => {
  const intl = useIntl();
  const classes = useStyles();
  const [openManageAppointments, setOpenManageAppointments] =
    useState<boolean>(false);
  const [openConfirmAppointments, setOpenConfirmAppointments] =
    useState<boolean>(false);
  const [openCancelAppointments, setOpenCancelAppointments] =
    useState<boolean>(false);
  const [contentPayloadData, setContentPayloadData] = useState<any>({});
  const today = moment();

  let date = moment(props.contentPayload?.appointment_datetime);
  let year = parseInt(date.format("YYYY")) + 543;

  const ACTION_CARD = "CMA_" + props.contentPayload?.patient_appointment_id;

  useEffect(() => {
    if (!props.remindInComing && !props.DISABLE_CREATE_APPOINTMENT) {
      props.onEvent({
        message: "HandlePatientAppointmentView",
        params: {
          patientId: props.patientData?.id,
          viewIndex: 8,
          card: ACTION_CARD,
        },
      });
    } else {
      setContentPayloadData(props.contentPayload);
    }
  }, []);

  useEffect(() => {
    if (!props.remindInComing && !props.DISABLE_CREATE_APPOINTMENT) {
      handleCheckAppointment();
    }
  }, [props.patientAppointmentList]);

  const telePhoneNumber = useMemo(() =>{
    const filterOrg = props.organizationList.find((item:any) => item.code === "1")
    return filterOrg?.telephone_1 || ""
  }
  ,[props.organizationList])

  const handleCheckAppointment = async () => {
    let appointment = (props.patientAppointmentList?.items || []).find(
      (item: any) => item.id === props.contentPayload?.patient_appointment_id
    );

    if (
      appointment?.status === 1 &&
      moment(appointment.estimated_at_iso).isBefore(today)
    ) {
      appointment = { ...appointment, status: 5 };
    }

    const contentPayload = {
      ...props.contentPayload,
      ...(appointment?.status && { status: appointment?.status }),
      ...(!appointment?.status && { status: 5 }),
    };

    date = moment(contentPayload?.appointment_datetime);
    year = parseInt(date.format("YYYY")) + 543;

    setContentPayloadData(contentPayload);
  };

  const onSelectContentPayload = () => {
    setOpenManageAppointments(true);
  };

  const onConfirmAppointment = () => {
    props.onEvent({
      message: "HandleConfirmAppointment",
      params: {
        id: contentPayloadData.patient_appointment_id,
        selectedItem: {
          id: contentPayloadData.patient_appointment_id,
          doctor_full_name: contentPayloadData.doctor_name,
        },
        card: ACTION_CARD,
      },
    });

    setOpenConfirmAppointments(false);
    setOpenManageAppointments(false);
  };

  const onCancelAppointment = () => {
    props.onEvent({
      message: "HandleCancelAppointment",
      params: {
        id: contentPayloadData.patient_appointment_id,
        card: ACTION_CARD,
      },
    });

    setOpenCancelAppointments(false);
    setOpenManageAppointments(false);
  };

  const handleCheckStatus = (status: any, style: string) => {
    if (status === 2) {
      return style === "background" ? "#96CF9EFF" : "#27AE60FF";
    } else if (status === 5) {
      return style === "background" ? "#7676801F" : "#3C3C434A";
    } else {
      return style === "background" ? "#1AB3CB" : "";
    }
  };

  return (
    <div style={{ fontSize: "12px" }}>
      {props.remindInComing && !props.DISABLE_CREATE_APPOINTMENT && (
        <div
          style={{
            fontSize: "12px",
            margin: "10px 0",
            minWidth: "max-content",
          }}
        >
          {intl?.formatMessage({
            id: "นัดหมายของท่านใกล้ถึงวันและเวลาที่นัดหมาย",
          })}
        </div>
      )}
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        {props.remindInComing
          ? intl?.formatMessage({ id: "รายการนัดหมายที่ใกล้มาถึง" })
          : intl?.formatMessage({
              id: props.DISABLE_CREATE_APPOINTMENT
                ? "รายการนัดหมาย"
                : "กรุณายืนยันนัดหมายเพื่อเข้าพบแพทย์",
            })}
      </div>
      {props.loadingStatus[ACTION_CARD] ? (
        <div
          style={{
            fontWeight: "bold",
            color: "var(--primary-theme-color)",
            margin: "10px 0px",
          }}
        >
          <Skeleton animation="pulse" width={"85%"} />
        </div>
      ) : (
        <div
          style={{
            fontWeight: "bold",
            color: "var(--primary-theme-color)",
            margin: "10px 0px",
          }}
        >
          {contentPayloadData?.doctor_name}
        </div>
      )}

      {props.loadingStatus[ACTION_CARD] ? (
        <Skeleton animation="pulse" width={"85%"} />
      ) : (
        <div>{contentPayloadData?.division_name}</div>
      )}
      <div
        style={{
          display: "flex",
          ...(props.remindInComing &&
            !props.DISABLE_CREATE_APPOINTMENT && { marginBottom: "10px" }),
        }}
      >
        <div className="card-appointment-detail">
          <Icon
            circular
            name="calendar alternate outline"
            size="small"
            style={{
              background: "#E3F3F9",
            }}
          />
          {props.loadingStatus[ACTION_CARD] ? (
            <div style={{ fontSize: "12px" }}>
              <Skeleton animation="pulse" width={"50px"} />
            </div>
          ) : (
            <div style={{ fontSize: "12px" }}>{`${date.format(
              "DD/MM"
            )}/${year}`}</div>
          )}
        </div>
        <div className="card-appointment-detail">
          <Icon
            circular
            name="clock outline"
            size="small"
            style={{
              background: "#E3F3F9",
            }}
          />
          {props.loadingStatus[ACTION_CARD] ? (
            <Skeleton animation="pulse" width={"50px"} />
          ) : (
            <div style={{ fontSize: "12px" }}>{date.format("HH:mm")}</div>
          )}
        </div>
        <div
          className="card-appointment-telemed"
          style={{
            background: "#96CF9E",
            marginRight: "10px",
          }}
        >
          <Icon name="hospital outline" />
          <div>{"OPD"}</div>
        </div>
      </div>
      {props.DISABLE_CREATE_APPOINTMENT ? (
        <div
          style={{
            fontSize: "12px",
            margin: "10px 0",
          }}
        >
          {intl?.formatMessage({
            id: `ต้องการเปลี่ยนแปลงหรือยกเลิกนัดหมาย\n กรุณาติดต่อเจ้าหน้าที่ เบอร์โทรศัพท์ ${telePhoneNumber}`,
          })}
        </div>
      ) : (
        !props.remindInComing && (
          <>
            {props.loadingStatus[ACTION_CARD] ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "15px 0px",
                }}
              >
                <Skeleton variant="rounded" width={"80%"} height={"30px"} />
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="content-common-button"
                  onClick={() => onSelectContentPayload()}
                  style={{
                    background: handleCheckStatus(
                      contentPayloadData?.status,
                      "background"
                    ),
                    margin: "15px 0px",
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    gap: "3px",
                  }}
                >
                  {contentPayloadData?.status === 2 && (
                    <Image src={"/images/ishealth/circum_calendar.png"} />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ...(contentPayloadData?.status === 2 && {
                        paddingTop: "2px",
                      }),
                      color: handleCheckStatus(
                        contentPayloadData?.status,
                        "color"
                      ),
                    }}
                  >
                    {intl?.formatMessage({
                      id:
                        contentPayloadData?.status === 2
                          ? "ยืนยันนัดหมายแล้ว"
                          : "จัดการนัดหมาย",
                    })}
                  </div>
                </div>
              </div>
            )}
          </>
        )
      )}

      <Modal
        open={openManageAppointments}
        onClose={() => setOpenManageAppointments(false)}
        size="mini"
      >
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {intl?.formatMessage({
                id: "ท่านต้องการยืนยันนัดหมายใช่หรือไม่ ?",
              })}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "30px 0px" }}>
              {`${intl?.formatMessage({ id: "วันที่" })} ${date.format(
                "DD MMMM"
              )} ${year}  ${date.format("HH:mm")} \n${
                contentPayloadData.division_name
              }`}
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#96CF9E",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => setOpenConfirmAppointments(true)}
              >
                <div>
                  <FormattedMessage id="common.approve" />
                </div>
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#25A7B9",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => {
                  setOpenManageAppointments(false);
                  props.history.push(
                    `/editAppointmentIH/${contentPayloadData.patient_appointment_id}`
                  );
                }}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="appoint.postpone" />
                </div>
              </Button>
            </div>
            <div style={{ marginTop: 10 }}>
              <Button
                style={{
                  background: "#F69E97",
                  color: "white",
                  borderRadius: "20px",
                  width: "60%",
                }}
                onClick={() => setOpenCancelAppointments(true)}
              >
                <div>
                  <FormattedMessage id="appoint.cancel" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>

      <Modal open={openConfirmAppointments} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {intl?.formatMessage({
                id: "ท่านต้องการยืนยันนัดหมายใช่หรือไม่ ?",
              })}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "30px 0px" }}>
              {`วันที่ ${date.format("DD MMMM")} ${year}  ${date.format(
                "HH:mm"
              )} \n${contentPayloadData?.division_name}`}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => setOpenConfirmAppointments(false)}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => onConfirmAppointment()}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>

      <Modal open={openCancelAppointments} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.modalHeader}>
              {intl?.formatMessage({
                id: "ท่านต้องการยกเลิกนัดหมายนี้หรือไม่",
              })}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "40px 0px" }}>
              {intl?.formatMessage({ id: "หากท่านยกเลิกแล้ว" }) +
                "\n" +
                intl?.formatMessage({ id: "จะต้องทำนัดหมายใหม่" })}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => setOpenCancelAppointments(false)}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={() => onCancelAppointment()}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>
    </div>
  );
};

export default CardManageAppointments;
