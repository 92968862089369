import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import {
  Button,
  Divider,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
} from "semantic-ui-react";

import { DevModeType, LoginInfoInfo } from "./TypeModal";
import { error } from "console";
// import { logoSquare } from "/images/ishealth/logo-square.png";

import InputFiledCircular from "react-lib/apps/IsHealth/IHMobile/InputFiledCircular";
import { useIntl } from "react-intl";

type MobLoginTypes = {
  onEvent?: any;
  history: any;
  loginInfo?: LoginInfoInfo;
  loading?: boolean;
  setProps?: any;
  devMode?: DevModeType;
  company?: string;
};

const MobLogin = (props: MobLoginTypes) => {
  const intl = useIntl();
  const [hindPassword, setHindPassword] = useState<boolean>(true);
  const [pattern, setPattern] = useState<string>("");
  console.log(" ~ MobLogin:");

  useEffect(() => {
    if (window.MobNative?.getDevMode) {
      window.MobNative?.getDevMode();
    } else if (window.iosNative?.getDevMode) {
      window.iosNative?.getDevMode();
    }
  }, []);

  useEffect(() => {
    if (pattern.includes("121212121212")) {
      console.log("devMode 1:");
      setDevModeNative(true, 1);
    } else if (pattern.includes("1122222222")) {
      console.log("devMode 2:");
      setDevModeNative(true, 2);
    } else if (pattern.includes("111111111122")) {
      console.log("devMode 3 : ");
      setDevModeNative(true, 3);
    }
  }, [pattern]);

  const handleGotoRegister = () => {
    props.history.push("/register");
  };

  const setDevModeNative = (active: boolean, version: number) => {
    console.log(" ~ active:", active, " version: ", version);
    if (window.MobNative?.saveDevMode) {
      console.log(" ~ MobNative:", active);
      window.MobNative?.saveDevMode(`${active}`, `${version}`);
      setPattern("");
    }
    if (window.iosNative?.saveDevMode) {
      console.log(" ~ iosNative:", active);
      window.iosNative?.saveDevMode(`${active}`, `${version}`);
      setPattern("");
    }
  };

  const checkDevMode = (patternInput: string) => {
    if (!props.devMode?.devMode) {
      let patternInputFull = pattern + patternInput;
      patternInputFull.substring(patternInputFull.length - 12);
      setPattern(patternInputFull);
    }
  };

  const handleGoto = (goto: string) => {
    switch (goto) {
      case "register":
        HandleDefault();
        props.history.push("/register");
        break;
      case "forgotPass":
        HandleDefault();
        props.setProps("errorMessage", "");
        props.history.push("/forgot-password");
        break;
      case "login":
        HandleDefault();
        if (
          !props.loginInfo?.error?.username &&
          !props.loginInfo?.error?.password &&
          !props.loading
        ) {
          props.onEvent({
            message: "HandleLogin",
            params: { history: props.history },
          });
        }
        break;
    }
  };

  const HandleDefault = () => {
    props.setProps("profileInfo", {});
    props.setProps("registerInfo", { error: {} });
    props.setProps("refCode","");
    props.setProps("dumpEmailRegistered", {});
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.loginInfo?.error || {};
    if (
      (filed === "password" || filed === "username") &&
      props.loginInfo?.error?.message
    ) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetLoginInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleCheckField = () => {
    let disable = false;
    if (props.loginInfo?.error?.username || props.loginInfo?.error?.password) {
      disable = true;
    }
    return disable;
  };

  const HandleSelectedLogo = (company: string) => {
    if (company === "IH-MSK") {
      return `url("/images/msk/msk-login-logo.png")`;
    } else {
      return `url("/images/ishealth/sapiens-logo-256.png")`;
    }
  };

  return (
    <div className="content-header">
      <div
        className="dev-mode"
        style={{ ...(!props.devMode?.devMode && { display: "none" }) }}
        onClick={() => {
          setDevModeNative(false, 1);
        }}
      >
        <div className="inner-square">
          {`devMode ${props.devMode?.devVersion}`}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          minHeight: "240px",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
        onClick={() => checkDevMode("1")}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            height: "80%",
            ...(props.company !== "IH-MSK" && { backgroundColor: "white" }),
          }}
        >
          {props.company === "IH-MSK" ? (
            <Image src="/images/msk/msk-login-logo.png" />
          ) : (
            <div
              style={{
                backgroundImage: `url("/images/ishealth/sapiens-logo-256.png")`,
                backgroundPosition: "center",
                width: "240px",
                height: "190px",
              }}
            ></div>
          )}
        </div>
        <div
          style={{
            backgroundImage: `url("/images/msk/msk-background.png")`,
            backgroundPosition: "top",
            backgroundSize: "cover",
            height: "30%",
            width: "100%",
            position: "absolute",
            bottom: "0px",
            ...(props.company !== "IH-MSK" && {display: "none"})
          }}
        ></div>
      </div>

      {/* Form Login */}

      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div
            style={{
              color: props.company === "IH-MSK" ? `var(--secondary-color-third)` : "#B7D7E2",
              fontWeight: "bold",
              fontSize: "18px",
              marginBottom: "20px",
            }}
            onClick={() => checkDevMode("2")}
          >
            เข้าสู่ระบบ
          </div>

          <InputFiledCircular
            id="login-username"
            typeIcon="LOCK"
            error={
              props.loginInfo?.error?.username ||
              props.loginInfo?.error?.message ||
              ""
            }
            hideTextErr={!!props.loginInfo?.error?.message}
            label={intl.formatMessage({ id: "ชื่อผู้ใช้งาน" })}
            value={props.loginInfo?.username || ""}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetLoginInfo",
                params: { key: "username", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("username")}
          />

          <InputFiledCircular
            id="login-password"
            typeIcon="PASSWORD"
            error={
              props.loginInfo?.error?.password ||
              props.loginInfo?.error?.message ||
              ""
            }
            label={intl.formatMessage({ id: "รหัสผ่าน" })}
            value={props.loginInfo?.password || ""}
            type={"password"}
            hindPassword={hindPassword}
            onClickHind={() => setHindPassword(!hindPassword)}
            onChange={(e: any, v: InputProps) => {
              props.onEvent({
                message: "HandleSetLoginInfo",
                params: { key: "password", value: v.value },
              });
            }}
            onClickClearError={() => handleClearError("password")}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: "var(--primary-font-color)",
                textAlign: "end",
                margin: "10px 0",
              }}
              onClick={() => handleGoto("forgotPass")}
            >
              ลืมรหัสผ่าน?
            </div>
          </div>

          <div
            style={{
              width: "100%",
              flex: "1",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="ishealth-buttom">
                <Button
                  disabled={handleCheckField()}
                  loading={props.loading}
                  onClick={() => handleGoto("login")}
                >
                  เข้าสู่ระบบ
                </Button>
              </div>
            </div>
            <Divider
              horizontal
              inverted
              style={{ color: props.company === "IH-MSK" ? `var(--secondary-color-third)`: "#A2BFC9", fontSize: "14px" }}
            >
              หรือ
            </Divider>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="ishealth-buttom">
                <Button
                  style={{
                    ...(props.company === "IH-MSK" && {
                      background: `var(--primary-color-second)`,
                      color: `var(--secondary-color-third)`,
                    }),
                  }}
                  onClick={() => handleGoto("register")}
                >
                  ลงทะเบียน
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MobLogin.displayname = "Login";
export default MobLogin;
