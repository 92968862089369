import React, {
    useState,
    useMemo,
    SyntheticEvent,
    useEffect,
  } from "react";
  // MUI
  import Dialog from "@mui/material/Dialog";
  import DialogContent from "@mui/material/DialogContent";
  import Slide from "@mui/material/Slide";
  import { TransitionProps } from "@mui/material/transitions";
  
  import { makeStyles } from "@mui/styles";
  
  import { Form } from "semantic-ui-react";
  
  import moment from "moment";
  
  // @ts-ignore
  import DatePicker from "react-mobile-datepicker";
  
  // I.Styles
  import { diaglogStyles } from "./BottomSheetOption";
import { useIntl } from "react-intl";
  
  // Types
  type DateScrollPickerProps = {
    id?:string;
    value?: moment.Moment;
    label?: string;
    required?: boolean;
    dateFormat: string;
    maxDate?: moment.Moment;
    disabled?: boolean;
    // callback
    onChange?: (date: Date) => any;
  };
  
  // Styles
  const COLORS = {
    backdrop: "rgba(57, 57, 57, 0.25)",
    grey: "#2c2c2c",
    title: "rgba(121, 120, 120, 1)",
    blue: "var(--blue-bdms-color)",
    divider: "#ECECEC",
    light_grey: "#868686",
  };
  
  const useStyles = makeStyles(() => ({
    dialog: diaglogStyles,
    container: {
      padding: "15px 0 0",
      backgroundColor: "white",
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      "& .title": {
        color: COLORS.title,
        fontSize: "1rem",
        padding: "6px 0.2em 5px",
      },
      "& .datepicker": {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        "& .datepicker-scroll > li": {
          fontWeight: "normal",
          fontSize: "1rem",
        },
        "& .datepicker-navbar": {
          border: "none",
          padding: 0,
          flexDirection: "row-reverse",
          "& .datepicker-navbar-btn": {
            width: "50%",
            padding: "0 0 3px",
            height: "auto",
            fontSize: "1rem",
            WebkitTapHighlightColor: "transparent",
            border: "none",
            userSelect: "none",
            "&:nth-child(1)": {
              color: COLORS.blue,
            },
            "&:nth-child(2)": {
              color: "black",
            },
          },
        },
        "& .datepicker-wheel": {
          borderTop: `2px solid ${COLORS.light_grey}`,
          borderBottom: `2px solid ${COLORS.light_grey}`,
        },
        "& .datepicker-content": {
          padding: "1rem 5rem",
        },
        "& .datepicker-header": {
          color: "unset",
          border: "unset",
          textAlign: "left",
          marginBottom: "-1rem",
        },
      },
    },
    input: {
      "& .ui.disabled.input input": {
        background: "transparent !important",
        border: "1px solid #E4E4E4 !important",
      },
    },
  }));
  
  // Const
  const monthMap = {
    "1": "Jan",
    "2": "Feb",
    "3": "Mar",
    "4": "Apr",
    "5": "May",
    "6": "Jun",
    "7": "Jul",
    "8": "Aug",
    "9": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  } as Record<string, string>;
  
  const dateConfig = {
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
  
    month: {
      format: (value: any) => monthMap[value.getMonth() + 1],
      caption: "Mon",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };
  
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  const CURRENT_DATE = new Date();
  
  const DateScrollPicker = (props: DateScrollPickerProps) => {
  const intl = useIntl();
    const classes = useStyles();
  
    const [isOpen, setIsOpen] = useState(false);
    const [maxDate, setMaxDate] = useState<moment.Moment | null>(null);
    const [minDate, setMinDate] = useState<moment.Moment | null>(null);
  
    useEffect(() => {
      if (isOpen) {
        setMaxDate(props.maxDate?.clone().add(1, "days") || null);
        setMinDate(moment([1770, 1, 1]) || null);
  
        if (props.maxDate) {
          setTimeout(() => {
            setMaxDate(props.maxDate?.clone() || null);
          }, 250);
        }
      }
    }, [isOpen, props.maxDate]);
  
    const date = useMemo(() => {
      const momentDate = props.value?.isValid() ? props.value : "";
  
      return momentDate ? momentDate.toDate() : "";
    }, [props.value]);
  
    const value = useMemo(() => {
      return date ? props.value?.format(props.dateFormat) : "";
    }, [date, props.value, props.dateFormat]);
  
    const handleCancel = () => {
      setIsOpen(false);
    };
  
    const handleSelect = (time: Date) => {
      props.onChange?.(time);
  
      setIsOpen(false);
    };
  
    const handleOpen = (e: SyntheticEvent) => {
      setIsOpen(true);
    };
  
    const handleClose = () => {
      setIsOpen(false);
    };
  
    return (
      <>
        {/* <Form.Input
          className={classes.input}
          value={date ? props.value?.format(props.dateFormat) : ""}
          label={props.label}
          required={props.required}
          open={false}
          icon="calendar alternate outline"
          readOnly={true}
          disabled={true}
          onClick={handleOpen}
        /> */}
        <Form.Select
          id={props.id}
          required={props.required}
          value={value}
          label={props.label}
          placeholder=""
          open={false}
          disabled={props.disabled}
          selection={true}
          options={value ? [{ key: value, text: value, value }] : []}
          icon="calendar alternate outline"
          onClick={handleOpen}
        />
  
        <Dialog
          open={isOpen}
          className={`smart-dialog-container ${classes.dialog}`}
          TransitionComponent={Transition}
          onClose={handleClose}
        >
          <DialogContent>
            <div className={classes.container}>
              <DatePicker
                value={date || CURRENT_DATE}
                isOpen={isOpen}
                isPopup={false}
                showHeader={true}
                dateConfig={dateConfig}
                theme="android"
                //dateFormat={["DD", "MM", "YYYY"]}
                // dateFormat={[`${testMonth}/DD`, "hh", "mm"]}
                max={maxDate ? maxDate.toDate() : undefined}
                min={minDate ? minDate.toDate() : undefined}
                confirmText="OK"
                cancelText="CANCEL"
                customHeader={<div className="title">{props.label}</div>}
                onSelect={handleSelect}
                onCancel={handleCancel}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  export default React.memo(DateScrollPicker);
  