import React, { useState } from "react";
import { Image, Input, InputProps } from "semantic-ui-react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import { LoginInfoInfo } from "./TypeModal";
import { error } from "console";
import { type } from "os";
import { useIntl } from "react-intl";

// import { logoSquare } from "/images/ishealth/logo-square.png";s

type InputFiledCircularProps = {
  id?:string;
  error?: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  iconImage?: string;
  typeIcon?: "LOCK" | "PASSWORD" | "SMS" | "CID" | "USER_LOCK";
  type?: string;
  hindPassword?: boolean;
  onChange?: (event: any, data: InputProps) => void;
  onClickHind?: Function;
  onClickClearError?: Function;
  hideTextErr?: boolean;
};

const Icons = {
  lock: "/images/ishealth/ic_user_lock.png",
  password: "/images/ishealth/ic_password.png",
  sms: "/images/ishealth/ic_mobile_sms.png",
  cid: "/images/ishealth/ic_cid.png",
  userLock: "/images/ishealth/ic_user_lock.png",
};

const InputFiledCircular = (props: InputFiledCircularProps) => {
  const handleCheckTypeIcon = (type: string) => {
    switch (type) {
      case "LOCK":
        return Icons.lock;
      case "PASSWORD":
        return Icons.password;
      case "SMS":
        return Icons.sms;
      case "CID":
        return Icons.cid;
      case "USER_LOCK":
        return Icons.userLock;

      default:
        return Icons.lock;
    }
  };

  return (
    <div onClick={() => props.onClickClearError?.()}>
      {props.label && (
        <div style={{ margin: "5px 0px" }}>
          <label
            style={{
              fontSize: "14px",
              color: "var(--primary-font-color)",
              fontWeight: "bold",
            }}
          >
            {props.label}
          </label>
        </div>
      )}
      <div
        className={`content-filed-input`}
        style={{
          ...(props.error && { border: "1px solid red" }),
          ...(props.disabled && { opacity: "0.6" }),
        }}
      >
        {(props.iconImage || props.typeIcon) && (
          <div className="content-filed-circular">
            <Image
              style={{ width: "15px" }}
              src={
                props.iconImage
                  ? props.iconImage
                  : props.typeIcon
                  ? handleCheckTypeIcon(props.typeIcon)
                  : Icons.lock
              }
            />
          </div>
        )}

        {props.type === "password" ? (
          <Input
            id={props.id}
            className="input"
            value={props.value || ""}
            icon={{
              name: props.hindPassword ? "eye slash" : "eye",
              link: true,
              onClick: (e: any) => {
                props.onClickHind?.();
              },
            }}
            type={props.hindPassword ? "password" : "input"}
            onChange={props.onChange}
          />
        ) : (
          <Input
            id={props.id}
            className="input"
            value={props.value || ""}
            disabled={props.disabled}
            onChange={props.onChange}
          />
        )}
      </div>
      {(props.error && !props.hideTextErr) && <div id={`${props.id}-error-message`} className="input-error-message">{props.error}</div>}
    </div>
  );
};

InputFiledCircular.displayname = "inputFiledCircular";
export default InputFiledCircular;
