var _adapter: any = null;
const getAdapter = () => {
  if(_adapter !== null){
    return _adapter
  }
  _adapter = new adapterErrorNetwork()
  console.log(_adapter, "_settings")
  return _adapter
}

class adapterErrorNetwork {
  errorNetworkRef: any;

  constructor() {
    this.errorNetworkRef = null;
  }

  setRef(ref: any) {
    this.errorNetworkRef = ref;
  }

  getRef() {
    return this.errorNetworkRef;
  }

  getInstance () { 
    return this
  }
}

export default getAdapter();
