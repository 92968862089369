import React, { useEffect, useState } from "react";

import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
import {
  Button,
  Divider,
  Form,
  Icon,
  Image,
  Input,
  InputProps,
  Modal,
  Radio,
  Transition,
} from "semantic-ui-react";
import MobHeader from "./MobHeader";
import InputOTP from "./InputOTP";
import { registerInfo } from "./TypeModal";

import InputFiledCircular from "./InputFiledCircular";
import MessageErrorCurvedDialog from "react-lib/apps/IsHealth/Common/MessageErrorCurvedDialog";
import { useIntl } from "react-intl";
import { State } from "patient-lib/MobIsHealthInterface";

type MobVerifyOtpTypes = {
  onEvent?: any;
  errorMassage?: string;
  errorCheck?: boolean;
  loading?: boolean;
  history: any;
  refCode?: string;
  register?: registerInfo;
  setProps: any;
} & Pick<State, "dumpEmailRegistered">;

const MobVerifyOtp = (props: MobVerifyOtpTypes) => {
  const intl = useIntl();
  const [changMobileNumber, setChangMobileNumber] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<boolean>(false);
  const [sendOTPType, setSendOTPType] = useState<string>("email");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  useEffect(() => {
    if (props.register?.OTPType === "email") {
      setPhoneNumber(props.register?.email || "");
    } else {
      setPhoneNumber(props.register?.phoneNumber || "");
    }
    setErrorField(
      !!(
        props.register?.autoField &&
        !(props.register?.email || props.register?.phoneNumber)
      )
    );
  }, [props.register]);

  const handleCheckOtp = () => {
    if (
      props.dumpEmailRegistered?.email === props.register?.email &&
      props.dumpEmailRegistered?.username === props.register?.username &&
      !props.refCode
    ) {
      HandleRequestOtp();
    } else if (!props.refCode) {
      HandleGetToken();
    } else {
      props.onEvent({
        message: "HandleRequestTokenWithEmail",
        params: { history: props.history },
      });
    }
  };

  const checkFeild = () => {
    if (
      sendOTPType === "email" &&
      (props.register?.email?.length == 0 || !props.register?.email)
    ) {
      return true;
    } else if (
      sendOTPType === "phone" &&
      (props.register?.phoneNumber?.length == 0 || !props.register?.phoneNumber)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckField = () => {
    let disable = false;
    if (
      props.register?.error?.email ||
      props.register?.error?.phoneNumber ||
      ((props.register?.otpCode?.length || 0) < 6 && props.refCode) ||
      errorField ||
      checkFeild()
    ) {
      disable = true;
    }

    return disable;
  };

  const HandleGetToken = () => {
    console.log("saika ~ HandleGetToken:");
    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "OTPType", value: sendOTPType },
    });

    props.onEvent({
      message: "HandleRegister",
      params: { viewRegister: "verify-otp", history: props.history },
    });
  };

  const HandleRequestOtp = () => {
    console.log("saika ~ HandleRequestOtp:");

    props.onEvent({
      message: "HandleRequestToken",
      params: {},
    });
  };

  const HandleGoBack = () => {
    if (props.refCode) {
      props.setProps("refCode", "");
      props.setProps("otpCode", "");
      props.setProps(`registerInfo.error.message`, "");
    } else {
      props.history.goBack();
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.register?.error || {};
    if (props.register?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  return (
    <div className="content-header">
      <MobHeader
        title={props.refCode ? "ยืนยัน OTP" : "ลงทะเบียน"}
        leftIconClick={HandleGoBack}
        history={props.history}
      />

      {/* Form Register */}
      <div className="content-detail">
        <div style={{ padding: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <Image src="/images/ishealth/register-images.png" />
          </div>
          {!props.refCode ? (
            ///////////////////////////////////////////////////////
            ////////////    None ref cod      /////////////////////
            ///////////////////////////////////////////////////////
            <>
              <div style={{ margin: "20px 0px" }}>
                <label
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "var(--primary-font-color)",
                    margin: "5px 0",
                  }}
                >
                  ช่องทางการส่ง OTP
                </label>
              </div>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <Radio
                  className="content-filed-radio"
                  label={intl.formatMessage({ id: "เบอร์โทรศัพท์" })}
                  value="phoneNumber"
                  disabled
                  checked={sendOTPType === "phoneNumber"}
                  onClick={() => {
                    // setSendOTPType("phoneNumber");
                  }}
                />
                <Radio
                  className="content-filed-radio"
                  label={intl.formatMessage({ id: "อีเมล" })}
                  value="email"
                  checked={sendOTPType === "email"}
                  onClick={() => {
                    setSendOTPType("email");
                  }}
                  style={{ margin: "0 10px" }}
                />
              </div>
              <InputFiledCircular
                id="register-contact-otp"
                typeIcon="SMS"
                error={
                  props.register?.error?.message &&
                  (props.register?.phoneNumber || props.register?.email)
                    ? props.register?.error?.message
                    : ""
                }
                value={
                  sendOTPType === "email"
                    ? props.register?.email
                    : props.register?.phoneNumber
                }
                disabled={errorField || props.register?.autoField}
                onChange={(e: any, v: InputProps) => {
                  props.onEvent({
                    message: "HandleSetValueRegisterInfo",
                    params: { key: sendOTPType, value: v.value },
                  });
                }}
                onClickClearError={() => handleClearError(sendOTPType)}
              />
              {/* <div
                className="content-filed-input"
                style={{ ...(props.errorCheck && { border: `1px solid red` }) }}
              >
                <div
                  className="content-filed-circular"
                  style={{ paddingLeft: "4px" }}
                >
                  <Image
                    style={{ width: "15px" }}
                    src={"/images/ishealth/ic_mobile_sms.png"}
                  />
                </div>
                <Input
                  value={
                    sendOTPType === "email"
                      ? props.register?.email
                      : props.register?.phoneNumber
                  }
                  placeholder=""
                  disabled={
                    !changMobileNumber && props.register?.phoneNumber
                      ? true
                      : false && sendOTPType === "phoneNumber"
                  }
                  onChange={(e: any, v: InputProps) => {
                    props.onEvent({
                      message: "HandleSetValueRegisterInfo",
                      params: { key: sendOTPType, value: v.value },
                    });
                  }}
                />
              </div> */}
              {errorField && (
                <div
                  style={{
                    margin: "20px 0px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      color: "red",
                      whiteSpace: "pre-line",
                      textAlign: "center",
                    }}
                  >
                    {`ไม่มีการบันทึกอีเมลของผู้ป่วยในระบบ`}
                  </label>
                </div>
              )}
              {!changMobileNumber && props.register?.phoneNumber && (
                <div
                  style={{
                    margin: "20px 0px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      color: "#1AB3CB",
                      margin: "10px 0",
                    }}
                    onClick={() => setWarningModal(true)}
                  >
                    เปลี่ยนข้อมูลสำหรับส่ง OTP
                  </label>
                </div>
              )}
            </>
          ) : (
            ///////////////////////////////////////////////////////
            ////////////    Have ref cod      /////////////////////
            ///////////////////////////////////////////////////////

            <>
              <div
                style={{
                  margin: "40px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "var(--primary-font-color)",
                    margin: "10px 0",
                    textAlign: "center",
                  }}
                >
                  โปรดป้อนรหัสยืนยันที่ส่งทาง
                </label>

                <div
                  style={{
                    fontSize: "25px",
                    color: "#1AB3CB",
                    background: "#FFF0E0",
                    margin: "10px 0",
                    padding: "18px",
                    borderRadius: "35px",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {phoneNumber}
                </div>
                <InputOTP
                  id="register"
                  length={6}
                  refCode={props.refCode || ""}
                  onChangeOTP={(otp: string) => {
                    props.onEvent({
                      message: "HandleSetValueRegisterInfo",
                      params: { key: "otpCode", value: otp },
                    });
                  }}
                  onReSend={HandleRequestOtp}
                  onKeyEnter={handleCheckOtp}
                  onClearError={handleClearError}
                  autoFocus
                  inputClassName={"otpInput"}
                  className={"otpContainer"}
                  minuteStart={5}
                  secondsStart={0}
                  errorMessage={
                    props.errorMassage || props.register?.error?.message || ""
                  }
                  inputStyle={{
                    ...((props.errorMassage ||
                      props.register?.error?.message) && {
                      border: "1px solid red",
                    }),
                  }}
                />
              </div>
            </>
          )}

          <div className="div-center-bottom">
            <div className="ishealth-buttom">
              <Button
                disabled={handleCheckField()}
                loading={props.loading}
                onClick={() => handleCheckOtp()}
              >
                ยืนยัน
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        open={warningModal}
        onClose={() => setWarningModal(false)}
        title={"ต้องการใช้ \nข้อมูลอื่นให้การรับ OTP ?"}
        labelDetail={
          "หากท่านเปลี่ยนช่องทางการส่ง OTP \n ท่านจะไม่สามารถเข้าถึงการใช้งาน \n และบริการบางอย่างในแอพพลิเคชั่นได้"
        }
        leftText={intl.formatMessage({ id: "ยกเลิก" })}
        leftOnClick={() => setWarningModal(false)}
        rightText={intl.formatMessage({ id: "ยืนยันการแก้ไข" })}
        rightOnClick={() => {
          setChangMobileNumber(true);
          setWarningModal(false);
        }}
      />
      <MessageErrorCurvedDialog
        open={errorField}
        textMessage={
          "โปรดติดต่อเจ้าหน้าที่เวชระเบียนของ รพ. เพื่ออัพเดตข้อมูลการส่ง OTP"
        }
      />
    </div>
  );
};

type ModalComponent = {
  open: boolean;
  onClose: Function;
  title: string;
  labelDetail: string;
  leftText: string;
  leftOnClick: Function;
  rightText: string;
  rightOnClick: Function;
};

export const ModalComponent = (props: ModalComponent) => {
  return (
    <Modal
      open={props.open}
      style={{
        borderRadius: "20px",
      }}
      onClose={props.onClose()}
    >
      <div
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <label
          style={{
            fontSize: "18px",
            color: "#1AB3CB",
            margin: "10px 0",
            whiteSpace: "pre-line",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            lineHeight: "30px",
            fontWeight: "bold",
          }}
        >
          {props.title}
        </label>
        <div
          style={{
            fontSize: "14px",
            color: "var(--primary-font-color)",
            whiteSpace: "pre-line",
            display: "flex",
            lineHeight: "20px",
            margin: "10px 0px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {props.labelDetail}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          <div
            className="content-button"
            style={{ background: "#B7D7E2", minWidth: "130px" }}
            onClick={props.leftOnClick()}
          >
            {props.leftText}
          </div>
          <div
            className="content-button"
            style={{ minWidth: "130px" }}
            onClick={props.rightOnClick()}
          >
            {props.rightText}
          </div>
        </div>
      </div>
    </Modal>
  );
};

MobVerifyOtp.displayname = "VerifyOtp";
export default MobVerifyOtp;
